import React, { createContext, useContext, useReducer } from 'react'
import { Team } from '../../types/teams'

interface CallCampaign {
  enabled: boolean
  ipp?: boolean
  campaign?: LeadFeed
  call?: Partial<Call> & {
    failed_user?: boolean
    failed_lead?: boolean
  }
  lead?: Lead
  submitted?: boolean
}

export interface Product {
  readonly id: string
  name: string
  enabled: boolean
  campaign_id: string
  sms_communication: boolean
  email_communication: boolean
  weight: number
  type: string
  retreaver_campaign_id: string
  cid_number_id: number
  cid_number: string
  timeout: number
  handler_id: number
  leadconduit_flow_id?: string
  organization_specific: boolean
  min_age?: number
  max_age?: number
  birthdate_required: boolean
  parent_product_id?: string
  campaign_type: 'user' | 'buyer'
  minimum_order?: number
}

export interface LeadFeed {
  id: string
  name: string
  product_id: string
  leads_per_day?: number
  user_id: string
  user: {
    id: string
    name: string
  }
  enabled: boolean
  is_full: boolean
  prepaid: boolean
  archived: boolean
  recipient_id?: string
  promotion_id?: string
  phone?: string
  retreaver_handler_id: string
  targeting_method: string
  zip_code_group_id?: string
  product?: Product
  states?: TargetedState[]
  promotion?: Promotion
  allocation_group_id?: string
  allocation_group?: any
  user_allocation?: any
  funding_source_type: 'points' | 'team-points'
  funded_by_team?: Team
}

export interface Call {
  id: string
  user_id: string // DEPRECATED - use user_lead.user_id instead
  product_id: string
  lead_id: string // DEPRECATED - use user_lead.lead_id instead
  lead_feed_id: string // DEPRECATED - use user_lead.lead_feed_id instead
  uuid: string
  number: string
  duration: number
  accepted: boolean
  answered: boolean
  active: boolean
  converted: boolean
  outcome: string
  recording_url: string
  call_source_id: string
  cost: number
  hung_up_by: string
  state: string
  state_id?: string
  type?: 'product' | 'inbound' | 'outbound'
  to?: string
  from?: string
  user_lead_id?: string
  user_lead?: UserLead
  created_at: string
}

export interface UserLead {
  id: string
  user_id: string
  lead_id: string
  total_sale_amount?: number
  commission_made?: number
  premium_data_access: boolean
  recipient_id?: string
  transaction_id?: string
  sold_as?: number
  message?: string
  sent_sms: boolean
  sent_email: boolean
  first_accessed_at?: string | Date
  hidden: boolean
  carrier?: string
  lead_feed_session_id?: string
  lead_feed_id?: string // DEPRECATED
  lead?: Lead
}

export interface TargetedState {
  readonly id: string
  lead_feed_id: string
  state: string
  state_id?: string
}

export interface Lead {
  readonly id: string
  name: string
  first_name: string
  last_name: string
  email: string
  phone?: string
  street_address?: string
  city?: string
  state?: string
  zip?: string
  birthdate?: string
  product?: Partial<Product>
  product_id?: string
  is_matched?: boolean
  hidden?: boolean
}

export interface Product {
  readonly id: string
  name: string
  enabled: boolean
  campaign_id: string
  sms_communication: boolean
  email_communication: boolean
  weight: number
  type: string
  retreaver_campaign_id: string
  cid_number_id: number
  cid_number: string
  timeout: number
  handler_id: number
  leadconduit_flow_id?: string
  organization_specific: boolean
  min_age?: number
  max_age?: number
  birthdate_required: boolean
  parent_product_id?: string
  campaign_type: 'user' | 'buyer'
  minimum_order?: number
  buffer?: number
  config?: any
}

export interface Promotion {
  readonly id: string
  discount?: number
  fixed_discount?: number
  code: string
  user_id?: string
  organization_id?: string
  starts_at?: Date | string
  ends_at?: Date | string
  uses?: number
  uses_per_user?: number
  marketplace: boolean
  lead_feeds: boolean
  assign_on_first_use: boolean
  archived: boolean
}

export const CallContext = createContext<CallCampaign>({ enabled: false })

export const CallCampaignDispatchContext = createContext<React.Dispatch<action> | null>(null)

type Props = {
  children: JSX.Element
}

export enum actionType {
  ENABLE = 'ENABLE',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  SUBMISSION = 'SUBMISSION',
}

interface action {
  type: actionType
  payload: CallCampaign
}

const callCampaignReducer = (state: CallCampaign, action: action) => {
  const { type, payload } = action

  switch (type) {
    case actionType.ENABLE:
      return {
        ...state,
        enabled: true,
        submitted: false,
      }
    case actionType.UPDATE:
      let updated = { ...state }
      if (payload.call) updated.call = { ...state.call, ...payload.call }
      updated.lead = payload.lead ? payload.lead : state.lead

      if (payload?.campaign) {
        updated = {
          ...payload,
          submitted: false,
        }
      } else {
        if (state?.campaign && payload?.call?.lead_feed_id === state.campaign.id) {
          updated = {
            ...payload,
            campaign: state.campaign,
            submitted: false,
          }
        }
      }

      if (
        state?.call?.type === 'outbound' &&
        state?.call?.active === true &&
        updated?.call?.active === false
      ) {
        // Call just ended
        if (updated.call.accepted === false) {
          // Failed to connect to User
          updated.call.failed_user = true
        } else if (updated.call.accepted === true && updated.call.answered === false) {
          // Failed to connect to Lead
          updated.call.failed_lead = true
        }
      }

      return updated
    case actionType.REMOVE:
      return {
        enabled: false,
        submitted: false,
      }
    case actionType.SUBMISSION:
      return {
        ...state,
        enabled: true,
        call: undefined,
        lead: undefined,
        submitted: true,
      }
    default:
      return state
  }
}

export function CallCampaignProvider({ children }: Props) {
  const [callCampaign, dispatch] = useReducer(callCampaignReducer, { enabled: false })

  return (
    <CallContext.Provider value={callCampaign}>
      <CallCampaignDispatchContext.Provider value={dispatch}>
        {children}
      </CallCampaignDispatchContext.Provider>
    </CallContext.Provider>
  )
}

export function useCallCampaign() {
  return useContext(CallContext)
}

export function useCallCampaignDispatch() {
  return useContext(CallCampaignDispatchContext)
}

export default CallCampaignProvider
