import {
  actionType,
  CallContext,
  useCallCampaignDispatch,
} from '../AgentPortal/CallBar/CallContext'
import { createContext, useContext, useEffect, useState } from 'react'
import { UserContext } from '../components/AuthProvider/auth_provider'
import { Channel, default as Pusher } from 'pusher-js/with-encryption'
import { useCampaigns } from './campaigns'
import backend from '../backend'
import { useTenant } from './TenantConfig'
import AlertUserToRefreshModal from '../components/AlertUserToRefreshModal/alert_user_to_refresh_modal'

interface PusherContextType {
  connected: boolean
  connect?: () => void
  disconnect?: () => void
  emit?: (event: string, data: any) => void
  client?: Pusher
}
const PusherContext = createContext<PusherContextType>({
  connected: false,
})
export const PusherProvider = (props: { children: JSX.Element }) => {
  const tenant = useTenant()
  const { updateCampaign } = useCampaigns()
  const { user } = useContext(UserContext)
  const callContext = useContext(CallContext)
  const dispatch = useCallCampaignDispatch()
  const [client, setClient] = useState<Pusher>()
  const [privateChannel, setPrivateChannel] = useState<Channel>()
  const [connected, setConnected] = useState(false)
  const [alertUserToRefreshModalIsOpen, setAlertUserToRefreshModalIsOpen] = useState(false)

  const connect = () => {
    if (!connected && !client) {
      initPusher()
    }
  }
  const disconnect = () => {
    if (client) {
      client.unbind_all()
      client.disconnect()
      setConnected(false)
    }
  }

  const emit = (event: string, data: any) => {
    if (privateChannel) {
      privateChannel.trigger(event, data)
    }
  }
  const initPusher = () => {
    const enabled = sessionStorage.getItem('pusher_enabled')
    if (!enabled) {
      sessionStorage.setItem('pusher_enabled', 'true')
      const token = localStorage.getItem('token')

      if (typeof client === typeof undefined) {
        Pusher.logToConsole = process.env.REACT_APP_PUSHER_DEBUG === 'true'
        const pusherKey = process.env.REACT_APP_PUSHER_APP_KEY

        if (pusherKey !== undefined) {
          const client = new Pusher(pusherKey, {
            cluster: 'us2',
            userAuthentication: {
              endpoint: `${process.env.REACT_APP_API_URL}/pusher/user-auth`,
              transport: 'ajax',
              headers: { Authorization: `Bearer ${token}` },
            },
            channelAuthorization: {
              endpoint: `${process.env.REACT_APP_API_URL}/pusher/channel-auth`,
              transport: 'ajax',
              headers: { Authorization: `Bearer ${token}` },
            },
          })

          client.connection.bind('connected', function () {
            setConnected(true)
            client.signin()
          })
          client.connection.bind('disconnect', function () {
            client.disconnect()
          })
          client.bind('call_data', function (data: any) {
            if (data.call) {
              if (!data.call.outcome && data.call.id === callContext?.call?.id) {
                if (dispatch)
                  dispatch({
                    type: actionType.UPDATE,
                    payload: {
                      enabled: true,
                      campaign: data.campaign,
                      call: data.call,
                      lead: data.lead,
                    },
                  })
              } else {
                if (dispatch)
                  dispatch({
                    type: actionType.UPDATE,
                    payload: {
                      enabled: true,
                      campaign: data.campaign,
                      call: data.call,
                      lead: data.lead,
                    },
                  })
              }
            } else {
              if (dispatch)
                dispatch({
                  type: actionType.UPDATE,
                  payload: {
                    enabled: true,
                    campaign: data.campaign,
                    call: undefined,
                  },
                })
            }

            if (data.campaign) {
              if (updateCampaign)
                updateCampaign(data.campaign.id, { enabled: data.campaign.enabled })
            }
          })

          client.bind('campaign_paused', async (data: any) => {
            if (data.campaign) {
              if (dispatch)
                dispatch({
                  type: actionType.REMOVE,
                  payload: {
                    enabled: true,
                    ipp: callContext?.ipp,
                    campaign: undefined,
                    call: data.call,
                  },
                })
              if (updateCampaign) updateCampaign(data.campaign.id, { enabled: false })
            }
          })
          client.bind('pusher:signin_success', function () {
            // @ts-expect-error FIXME
            const privateChannel = client.subscribe(`private-${user.id}.${tenant.id}`)
            setPrivateChannel(privateChannel)
            privateChannel.bind('pusher:subscription_succeeded', () => {
              privateChannel.trigger('client-init', {
                message: 'init',
              })

              privateChannel.unbind('pusher:subscription_succeeded') // unbind this event after it fires once
            })
          })

          client.bind('3ds_sub_success', function () {
            console.log('3DS authentication successful')
            window.top?.postMessage('3DS-authentication-success')
          })

          client.bind('3ds_sub_failed', function () {
            console.log('3DS authentication failed')
            window.top?.postMessage('3DS-authentication-failed')
          })

          const updateChannel = client.subscribe('notify_user')

          updateChannel.bind('new_version_soft_refresh', function () {
            console.log(
              'Change to API or Client detected. Requesting user refresh page to continue.'
            )
            setAlertUserToRefreshModalIsOpen(true)
          })

          updateChannel.bind('new_version_hard_refresh', function () {
            console.log('Breaking change to API or Client detected. Hard reloading.')
            window.location.reload()
          })

          setClient(client)
        }
      }
    }
  }

  const checkForCampaigns = async () => {
    if (!connected && connect) {
      connect()
    }
    const { body } = await backend.get('/pusher/enable') // todo update this to return true if on active call campaign OR Voice twilio number is present
    if (body.enable) {
      // initPusher()
    } else if (!body.has_active_call_campaign && callContext?.campaign?.enabled) {
      // on wake state, if there's an enabled campaign && the endpoint is returning false, disable campaign on UI
      let campaignToPause = callContext.campaign.id
      if (dispatch)
        dispatch({
          type: actionType.REMOVE,
          payload: {
            enabled: true,
            ipp: callContext?.ipp,
            campaign: undefined,
            call: callContext?.call,
          },
        })
      if (updateCampaign) updateCampaign(campaignToPause, { enabled: false })
    }
  }

  useEffect(() => {
    checkForCampaigns()
  }, [])

  const handleVisibilityChange = () => {
    if (document.visibilityState !== 'hidden') {
      checkForCampaigns()
    }
  }
  useEffect(() => {
    try {
      document.addEventListener('visibilitychange', handleVisibilityChange, false)
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <PusherContext.Provider value={{ connected, connect, client, disconnect, emit }}>
      {alertUserToRefreshModalIsOpen && (
        <AlertUserToRefreshModal
          alertUserToRefreshModalIsOpen={alertUserToRefreshModalIsOpen}
          setAlertUserToRefreshModalIsOpen={setAlertUserToRefreshModalIsOpen}
        />
      )}
      {props.children}
    </PusherContext.Provider>
  )
}
export const usePusher = () => useContext(PusherContext)
