import { createContext, useContext, useState } from 'react'
import { findIndex } from 'lodash'

import { LeadFeed } from '../AgentPortal/CallBar/CallContext'
import backend from '../backend'

interface CampaignsContextType {
  campaigns: LeadFeed[]
  loading: boolean
  updatedCampaign?: UpdatedCampaignType
  fetchCampaigns?: () => Promise<void>
  updateCampaign?: (id: string, patch: Partial<LeadFeed>) => void
}
interface UpdatedCampaignType {
  id: string
  enabled: boolean
}
const CampaignsContext = createContext<CampaignsContextType>({
  campaigns: [],
  loading: true,
})

export const CampaignsProvider = (props: { children: JSX.Element }) => {
  const [campaigns, setCampaigns] = useState<LeadFeed[]>([])
  const [updatedCampaign, setUpdatedCampaign] = useState<UpdatedCampaignType>()
  const [loading, setLoading] = useState(true)

  const fetchCampaigns = async () => {
    if (!loading) setLoading(true)
    const { body } = await backend.get('/lead-feeds')

    setCampaigns(body.data)
    setLoading(false)
  }

  const updateCampaign = (id: string, patch: Partial<LeadFeed>) => {
    if (campaigns && campaigns.length > 0) {
      const index = findIndex(campaigns, { id })
      const copy = [...campaigns]
      copy[index] = {
        ...copy[index],
        ...patch,
      }
      setCampaigns(copy)
    }
    if (patch.enabled !== undefined) setUpdatedCampaign({ id, enabled: patch.enabled })
  }

  return (
    <CampaignsContext.Provider
      value={{ campaigns, loading, updatedCampaign, fetchCampaigns, updateCampaign }}
    >
      {props.children}
    </CampaignsContext.Provider>
  )
}

export const useCampaigns = () => useContext(CampaignsContext)
