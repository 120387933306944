import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'
import styled from '@emotion/styled'

import { Box, Icon, WrapperButton, Inline, Text, Stack } from '@leadrilla/pulsar'
import useImage from '../../hooks/image'
import { isLeadrilla, useTenant } from '../../hooks/TenantConfig'
import { UserContext } from '../AuthProvider/auth_provider'
import { analyticsEvent } from '../../helpers/heap'
import iconMap from '../../../node_modules/@leadrilla/pulsar/dist/assets/icons/icon-map'
import { formatUSD } from '../../helpers/currency'
import { getName } from '../../helpers/user'
import { User } from '../../types/users'

export default function Header({
  height,
  openMobileMenu,
  user,
  impersonating,
  cancelImpersonation,
}: {
  height: string
  openMobileMenu: () => void
  user: User
  impersonating: boolean
  cancelImpersonation: () => void
}) {
  const tenant = useTenant()
  const { image: iconLogo } = useImage(`${tenant.name}-icon.svg`)
  const [showTeamFunds, setShowTeamFunds] = useState(false)

  const logoFilename = isLeadrilla() ? 'leadrilla-logo-blue.svg' : `${tenant.name}-logo.svg`
  const { image: tenantLogo } = useImage(logoFilename)
  const { removeUser, refreshUser } = useContext(UserContext)

  const StyledLogo = styled.img`
    max-height: ${tenant.config.logos?.dashboard_main?.width || '32px'};
    max-width: ${tenant.config.logos?.dashboard_main?.width || '120px'};
    transform: translateX(-50%);
  `

  useEffect(() => {
    refreshUser()
    document.addEventListener('click', function (evt) {
      let brandWrapperEle = document.getElementById('brand-wrapper-box'),
        targetEl: Node | null = evt.target as Node
      do {
        if (targetEl === brandWrapperEle) return
        targetEl = targetEl?.parentNode
      } while (targetEl)
      setShowUserDropdown(false)
    })
  }, [])

  const totalPoints =
    (user?.points || 0) + (tenant.config.teams.enabled ? user?.team_points || 0 : 0) || 0

  const [showUserDropdown, setShowUserDropdown] = useState(false)

  const toggleUserDropdown = () => {
    setShowUserDropdown(!showUserDropdown)
  }

  const logOut = () => {
    // @ts-expect-error FIXME
    analyticsEvent('User Logged Out')
    removeUser()
    window.location.assign('/')
  }

  return (
    <>
      {/* mobile */}
      <div className="lg:hidden">
        <Box inset="m" background={impersonating ? 'var(--colors-negative--subtle)' : undefined}>
          <div className="grid grid-cols-12">
            <div className="col-span-4">
              <WrapperButton onClick={openMobileMenu}>
                <Icon name="menu-tt" />
              </WrapperButton>
            </div>
            <div className="col-span-4 mx-auto">
              <NavLink to="/dash/campaigns">
                <StyledLogoMobile src={tenantLogo ?? ''} alt="logo" />
              </NavLink>
            </div>
            <div className="col-span-4">
              <Link to="/dash/billing">
                <Stack space="xxs">
                  <Text size="small" align="right">
                    Balance:
                  </Text>
                  <Text align="right" weight="stronger" tone="positive">
                    {formatUSD(user?.points || 0)}
                  </Text>
                </Stack>
              </Link>
            </div>
          </div>
        </Box>
      </div>

      {/* desktop */}
      <div className="hidden lg:block">
        <Box
          inset="m"
          display="flex"
          alignItems="center"
          justifyContent="between"
          height={height}
          background="var(--card--background)"
          position="relative"
        >
          <Box position="relative">
            <StyledBrandWrapper
              position="relative"
              insetSquish="s"
              onClick={toggleUserDropdown}
              id="brand-wrapper-box"
              impersonating={impersonating}
            >
              <Inline space="xs" alignY="center" grow={[null, 1, null]} nowrap>
                {isLeadrilla() ? (
                  <Icon name="gorilla" tone={impersonating ? 'negative' : 'subtle'} />
                ) : (
                  <StyledIconImage src={iconLogo ?? ''} />
                )}
                <StyledUserNameText weight="strong" tone={impersonating ? 'negative' : 'neutral'}>
                  {getName({ user })}
                </StyledUserNameText>
                <Icon name="arrow" tone="light" size="s" rotate={showUserDropdown ? 180 : 0} />
              </Inline>
            </StyledBrandWrapper>

            {showUserDropdown && (
              <StyledUserDropdown paddingBottom="s">
                <Stack space="xs">
                  <NavLink to="/dash/billing" onClick={toggleUserDropdown}>
                    <Box insetSquish="s">
                      <Inline space="m" alignY="center">
                        <Icon name="list-tt" size="m" />
                        <Text>Billing</Text>
                      </Inline>
                    </Box>
                  </NavLink>
                  <NavLink to="/dash/settings" onClick={toggleUserDropdown}>
                    <Box insetSquish="s">
                      <Inline space="m" alignY="center">
                        <Icon name="settings-tt" size="m" />
                        <Text>Settings</Text>
                      </Inline>
                    </Box>
                  </NavLink>
                  {tenant.config.custom_menu_links?.map(({ text, href, icon }) => (
                    <a href={href} target="_blank" rel="noreferrer">
                      <Box insetSquish="s">
                        <Inline space="m" alignY="center">
                          <Icon name={icon as keyof typeof iconMap} size="m" />
                          <Text>{text}</Text>
                        </Inline>
                      </Box>
                    </a>
                  ))}
                  <NavLink to="/" onClick={logOut}>
                    <Box insetSquish="s">
                      <Inline space="m" alignY="center">
                        <Icon size="m" name="logout-tt" />
                        <Text>Logout</Text>
                      </Inline>
                    </Box>
                  </NavLink>
                  {impersonating && (
                    <Box as="button" type="button" insetSquish="s" onClick={cancelImpersonation}>
                      <Inline space="m" alignY="center">
                        <Icon name="close" size="m" tone="negative" />
                        <Text tone="negative">Stop Impersonating</Text>
                      </Inline>
                    </Box>
                  )}
                </Stack>
              </StyledUserDropdown>
            )}
          </Box>

          <Box position="absolute" left="50%">
            <StyledLogo src={tenantLogo ?? ''} alt="logo" />
          </Box>

          <Box>
            <Inline space="m" alignY="center">
              <Link to="/dash/billing">
                <Stack space="xxs">
                  <Text size="small" align="right">
                    {showTeamFunds ? 'Personal Funds' : 'Available Funds'}
                  </Text>
                  <Text align="right" weight="stronger" tone="positive">
                    {showTeamFunds ? formatUSD(user?.points || 0) : formatUSD(totalPoints)}
                  </Text>
                </Stack>
              </Link>

              {tenant.config.teams?.enabled && (
                <>
                  {user?.teams && user.teams.length > 0 && !showTeamFunds && (
                    <div className="flex cursor-pointer items-center">
                      <Icon
                        name="expand"
                        size="m"
                        onClick={() => setShowTeamFunds(!showTeamFunds)}
                      />
                    </div>
                  )}
                  {showTeamFunds && (
                    <div
                      onClick={() => setShowTeamFunds(!showTeamFunds)}
                      className="flex cursor-pointer items-center space-x-6"
                    >
                      <div className="flex h-5 w-0.5 items-center bg-gray-300"></div>
                      <div>
                        <Stack space="xxs">
                          <div className="flex space-x-6">
                            <div className="space-y-1">
                              <Text size="small" align="right">
                                Team Funds
                              </Text>
                              <Text align="right" weight="stronger" tone="positive">
                                {formatUSD(user?.team_points || 0)}
                              </Text>
                            </div>
                            <div className="flex cursor-pointer items-center">
                              <Icon
                                name="minimize"
                                size="m"
                                onClick={() => setShowTeamFunds(!showTeamFunds)}
                              />
                            </div>
                          </div>
                        </Stack>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Inline>
          </Box>
        </Box>
      </div>
    </>
  )
}

const StyledBrandWrapper = styled(Box)`
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  height: 40px;
  cursor: pointer;
  z-index: 2;
  min-width: 180px;

  background: ${(p) =>
    p.impersonating ? 'var(--colors-negative--subtle);' : 'var(--colors-foreground);'};
`

const StyledUserDropdown = styled(Box)`
  position: absolute;
  width: 100%;
  top: 20px;
  padding-top: var(--space--xl);
  background: var(--colors-foreground);
  z-index: 1;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const StyledUserNameText = styled(Text)`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

const StyledIconImage = styled('img')`
  width: 26px;
  height: 26px;
`

const StyledLogoMobile = styled.img`
  max-height: 24px;
  max-width: 125px;
`

Header.propTypes = {
  height: PropTypes.string.isRequired,
  openMobileMenu: PropTypes.func.isRequired,
}
