import { useState, useEffect } from 'react'
import {
  Box,
  Dialog,
  Divider,
  Heading,
  Icon,
  Inline,
  PrimaryButton,
  RadioGroup,
  RadioItem,
  SecondaryButton,
  Stack,
  Text,
} from '@leadrilla/pulsar'
import TextAreaWithCharacterCount from '../../components/TextAreaWithCharacterCount/text_area_with_character_count'
import { useNotification } from '../../hooks/notification'
import backend from '../../backend'
import { analyticsEvent } from '../../helpers/heap'

export type PauseReason = 'busy' | 'budget' | 'low-volume' | 'unsatisfied' | 'other'

export interface CampaignPauseReasonModalProps {
  pauseAll: boolean
  campaignId?: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  afterSubmit?: () => void
  campaignType?: string
  vertical?: string
  product?: string
}

const CampaignPauseReasonModal = ({
  pauseAll,
  campaignId,
  open,
  setOpen,
  afterSubmit,
  campaignType,
  vertical,
  product,
}: CampaignPauseReasonModalProps) => {
  if (!pauseAll && !campaignId) throw new Error('provide a campaignId or set pauseAll to true')

  const [canPause, setCanPause] = useState(false)
  const [pauseReason, setPauseReason] = useState('')
  const [pauseReasonComment, setPauseReasonComment] = useState('')

  const showNotification = useNotification()

  const handlePauseReasonChange = (event: React.FormEvent<HTMLInputElement>) => {
    // TODO investigate why this is needed
    // @ts-ignore
    const reason: PauseReason = event.currentTarget.value
    setPauseReason(reason)
  }

  const handlePauseReasonCommentChange = (event: React.FormEvent<HTMLInputElement>) => {
    // TODO investigate why this is needed
    // @ts-ignore
    setPauseReasonComment(event.target.value)
  }

  useEffect(() => {
    let can = false
    if (['busy', 'budget', 'low-volume'].includes(pauseReason)) {
      can = true
    } else if (['unsatisfied', 'other'].includes(pauseReason)) {
      can = pauseReasonComment.length > 0
    }

    setCanPause(can)
  }, [pauseReason, pauseReasonComment])

  const submitForm = async () => {
    setOpen(false)

    const payload = {
      reason: pauseReason,
      comment: '',
    }

    if (pauseReason === 'unsatisfied' || pauseReason === 'other')
      payload.comment = pauseReasonComment

    try {
      let url: string

      if (pauseAll) {
        url = '/lead-feeds/pause/all'
      } else {
        url = `/lead-feeds/${campaignId}/pause`
      }

      const {
        body: { success, message },
      } = await backend.put(url, payload)

      if (message) {
        // @ts-expect-error FIXME
        showNotification({ type: success ? 'success' : 'error', message })
      }
      if (success && !pauseAll && campaignId) {
        try {
          analyticsEvent('Campaigns - Paused', {
            campaign_id: campaignId,
            type: campaignType,
            vertical,
            product,
            reason_for_pause: pauseReason,
            pause_type: 'user_initiated',
            comment: pauseReasonComment,
          })
        } catch (e) {
          console.log(e)
        }
      }
      setPauseReason('')
      setPauseReasonComment('')
    } catch (error) {
      // @ts-expect-error FIXME
      showNotification({
        type: 'error',
        message: (error as Error).message,
      })
    }

    if (typeof afterSubmit === 'function') afterSubmit()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      width="content"
    >
      <Box width="100%" maxWidth="343px" paddingTop="m" paddingBottom="l" paddingX="m">
        <Stack space="m">
          <Inline alignX="between" alignY="center">
            <Heading level={3}>Select Reason for Pause</Heading>

            <Box onClick={() => setOpen(false)} cursor="pointer">
              <Icon name="close" tone="light" size="m" />
            </Box>
          </Inline>

          <Divider />

          <Stack space="m" dividers>
            <Text component="p">
              Please provide a little information to help us continue to improve the product.
            </Text>

            <RadioGroup
              name="pauseReason"
              value={pauseReason}
              itemPaddingX="none"
              paddingY="none"
              dividers
              onChange={handlePauseReasonChange}
            >
              <RadioItem value="busy">
                <Stack space={'xs'}>
                  <Text weight={'stronger'}>Busy</Text>
                  <Text>I'll be back when I'm available.</Text>
                </Stack>
              </RadioItem>
              <RadioItem value="budget">
                <Stack space={'xs'}>
                  <Text weight={'stronger'}>Budget</Text>
                  <Text>I'll be back soon with more budget.</Text>
                </Stack>
              </RadioItem>
              <RadioItem value="low-volume">
                <Stack space={'xs'}>
                  <Text weight={'stronger'}>Low volume</Text>
                  <Text>I'm not receiving enough volume.</Text>
                </Stack>
              </RadioItem>
              <RadioItem value="unsatisfied">
                <Text weight={'stronger'}>I'm unsatisfied</Text>
              </RadioItem>
              <RadioItem value="other">
                <Text weight={'stronger'}>Other reason</Text>
              </RadioItem>
            </RadioGroup>
            {['unsatisfied', 'other'].includes(pauseReason) && (
              <TextAreaWithCharacterCount
                required
                maxLength={500}
                // TODO update pulsar TextArea to handle rows prop
                rows={6}
                name="comment"
                value={pauseReasonComment}
                placeholder="Type your comments here"
                onChange={handlePauseReasonCommentChange}
              />
            )}
          </Stack>

          <Divider />

          <Box>
            <Inline alignX="between" alignY="center" nowrap space="m" grow={[1, 1]}>
              <SecondaryButton onClick={() => setOpen(false)} fullWidth>
                Cancel
              </SecondaryButton>

              <PrimaryButton disabled={!canPause} onClick={submitForm} fullWidth>
                {pauseAll ? 'Pause All' : 'Pause'}
              </PrimaryButton>
            </Inline>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  )
}

export default CampaignPauseReasonModal
