import React from 'react'
import { useFetcher } from '../../hooks/fetcher'
import { Loader, Text, Box, Divider, Stack, TextLink } from '@leadrilla/pulsar'
import { LeadFeed } from './CallContext'
import { DateTime } from 'luxon'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { formatDuration } from '../../helpers/time'
import { History } from 'history'
import FormatStatus from './FormatStatus'

interface ICallHistoryProps {
  campaign: LeadFeed | undefined
  history: History
  setShowInfo: React.Dispatch<React.SetStateAction<boolean>>
}

const CallHistory: React.FC<ICallHistoryProps> = ({
  campaign,
  history,
  setShowInfo,
}: ICallHistoryProps) => {
  const { data, loading } = useFetcher(`/leads/${campaign?.id}/history`, {
    dependencies: [campaign],
  })

  const goToLead = (id: string) => {
    history.push(`/dash/leads/${id}`)
    setShowInfo(false)
    return true
  }

  return (
    <>
      <Loader height={350} loading={loading}>
        {data && data.length > 0 ? (
          <Stack space="m">
            <Divider />
            {data.map((item: any) => {
              return (
                <React.Fragment key={item.id}>
                  <Stack space="m">
                    <Box display="flex" justifyContent="between">
                      <Box>
                        <Stack space="m">
                          <Text size="small" tone="light">
                            {DateTime.fromISO(item.created_at).toFormat('MMM d, yyyy')}
                          </Text>
                          <Text size="big">
                            {parsePhoneNumberFromString(item.lead.phone, 'US')?.formatNational()}
                          </Text>
                          <Text size="small">
                            {formatDuration(parseInt(item.lead?.call?.duration, 10))}
                          </Text>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack space="m">
                          <FormatStatus data={item.statuses} />
                          <Box
                            onClick={() => {}}
                            width="100%"
                            id="presence-call-history-view-lead-button"
                          >
                            <Text align="right" weight="stronger">
                              {
                                // @ts-ignore
                                <TextLink onClick={() => goToLead(item.lead.id)}>View</TextLink>
                              }
                            </Text>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>
                </React.Fragment>
              )
            })}
          </Stack>
        ) : (
          <>
            <Text tone="light">No calls received yet</Text>
          </>
        )}
      </Loader>
    </>
  )
}
export default CallHistory
