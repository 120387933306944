import React, { useContext, useReducer, useState, useEffect } from 'react'
import {
  Text,
  Box,
  Divider,
  WrapperButton,
  Icon,
  Badge,
  Stack,
  Inline,
  Loader,
  TextLink,
  TextField,
  PrimaryButton,
} from '@leadrilla/pulsar'
import { actionType, CallContext, Lead, useCallCampaignDispatch } from './CallContext'
import styled from '@emotion/styled'
import { DateTime } from 'luxon'
import { useFetcher } from '../../hooks/fetcher'
import { analyticsEvent } from '../../helpers/heap'
import backend from '../../backend'
import { OK } from '../../constants/error_codes'
import { pick } from 'lodash'
import { useNotification } from '../../hooks/notification'
interface LeadInfoProps {
  leads?: Lead
}

export const getBirthdate = (birthdate: string) => {
  // @ts-ignore
  return DateTime.fromISO(birthdate).toUTC().toLocaleString('en-US')
}

const LeadInfo: React.FC<LeadInfoProps> = () => {
  const callContext = useContext(CallContext)
  const [editLead, setEditLead] = useState(false)
  const [status, setStatus] = useState<Status | undefined>()
  const [submitting, setSubmitting] = useState(false)
  const [birthdateMessage, setBirthdateMessage] = useState('')
  const [nameMessage, setNameMessage] = useState('')
  const sendNotification = useNotification()
  const dispatch = useCallCampaignDispatch()

  const emptyState = {
    id: null,
    first_name: '',
    last_name: '',
    birthdate: '',
    email: '',
    street_address: '',
    city: '',
    state: '',
    zip: '',
  }

  const reducer = (state: any, action: any) => {
    let newState
    if (action.reset) {
      newState = emptyState
      sessionStorage.removeItem('active-call-pii')
    } else {
      newState = {
        ...state,
        ...action,
      }
      sessionStorage.setItem('active-call-pii', JSON.stringify(newState))
    }
    analyticsEvent('Presence - Lead information filled', {
      active_call: callContext?.call?.active,
    })
    return newState
  }
  const clickEditLead = () => {
    setEditLead(!editLead)
  }
  const [lead, patchLead] = useReducer(reducer, emptyState)

  interface Status {
    readonly id: string
    product_id: string
    status: string
    name: string
    tone: 'light' | 'action' | 'positive' | 'negative' | 'caution' | 'info'
  }
  interface StatusResponse {
    status?: Status
  }
  const { loading: statusLoading } = useFetcher(`/leads/${callContext?.lead?.id}/status`, {
    afterFetch: (data: StatusResponse) => {
      setStatus(data.status)
    },
    //defaultData: [callContext.lead]
    dependencies: [callContext.lead],
  })

  const RenderStatus: React.FC = () => {
    return (
      <Loader loading={statusLoading} height={20}>
        {status && (
          <Badge tone={`${status.tone}`}>
            <span>{status.status}</span>
          </Badge>
        )}
      </Loader>
    )
  }

  const submit = async () => {
    if (submitting) return
    if (!lead.name) {
      setNameMessage('Name is required')
      return
    }
    setSubmitting(true)
    const { status } = await backend.put(`/leads/${lead.id}`, {
      ...lead,
      birthdate: lead.birthdate ? DateTime.fromFormat(lead.birthdate, 'yyyy-MM-dd').toSQL() : null,
    })
    if (status === OK) {
      if (dispatch)
        dispatch({
          type: actionType.UPDATE,
          payload: { enabled: true, lead: { ...lead } },
        })
      setEditLead(false)
      // close edit
    } else {
      // @ts-expect-error FIXME
      sendNotification({
        type: 'error',
        message: 'Something went wrong. Please refresh and try again.',
      })
    }

    setSubmitting(false)
  }

  const disableEdit = () => {
    return callContext.lead?.is_matched
  }

  useEffect(() => {
    patchLead({
      ...pick(callContext.lead, ['id', 'email', 'street_address', 'city', 'state', 'zip', 'name']),
      birthdate: callContext?.lead?.birthdate
        ? DateTime.fromISO(callContext.lead.birthdate).toFormat('yyyy-MM-dd')
        : '',
      first_name: callContext?.lead?.first_name ? callContext.lead.first_name : '',
      last_name: callContext?.lead?.last_name ? callContext.lead.last_name : '',
    })
  }, [callContext.lead])

  return (
    <Box>
      <Stack space="xs">
        <RenderStatus />
        <Box paddingBottom="m" display="flex" justifyContent="between" alignItems="center">
          <Text weight="stronger" size="big">{`${
            callContext.lead?.name ? callContext.lead?.name : 'Unmatched number'
          }`}</Text>
          {editLead ? (
            <WrapperButton onClick={clickEditLead} id="call-box-cancel-edit-lead">
              <Icon name="close" tone="action" size="m" />
            </WrapperButton>
          ) : (
            <>
              {!callContext.lead?.is_matched && (
                <WrapperButton
                  disabled={disableEdit()}
                  onClick={clickEditLead}
                  id="call-box-edit-lead"
                >
                  <Icon name="edit" tone="action" size="m" />
                </WrapperButton>
              )}
            </>
          )}
        </Box>
      </Stack>

      <Divider />

      <Box paddingY="m">
        {editLead ? (
          <>
            <Stack space="m">
              <TextField
                value={lead.name}
                onChange={(e) => patchLead({ name: e.target.value })}
                label="Name"
                id="presence-lead-information-field"
                message={nameMessage}
              />
              <TextField
                type="date"
                value={lead.birthdate}
                onChange={(e) => {
                  const regex = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/gm
                  if (!regex.test(e.target.value)) {
                    setBirthdateMessage('Invalid format')
                  } else {
                    setBirthdateMessage('')
                  }
                  patchLead({ birthdate: e.target.value })
                }}
                label="Date of birth"
                placeholder="mm/dd/yyyy"
                message={birthdateMessage}
                id="presence-lead-information-field"
              />
              <TextField
                value={lead.email}
                onChange={(e) => patchLead({ email: e.target.value })}
                type="email"
                label="Email"
                id="presence-lead-information-field"
              />
              <TextField
                value={lead.street_address}
                onChange={(e) => patchLead({ street_address: e.target.value })}
                label="Address"
                id="presence-lead-information-field"
              />
              <TextField
                value={lead.city}
                onChange={(e) => patchLead({ city: e.target.value })}
                label="City"
                id="presence-lead-information-field"
              />
              <Box display="flex" justifyContent="between">
                <Box marginRight="s">
                  <TextField
                    value={lead.state}
                    onChange={(e) => patchLead({ state: e.target.value })}
                    label="State"
                    id="presence-lead-information-field"
                  />
                </Box>
                <Box marginLeft="s">
                  <TextField
                    value={lead.zip}
                    onChange={(e) => patchLead({ zip: e.target.value })}
                    label="Zip code"
                    id="presence-lead-information-field"
                  />
                </Box>
              </Box>
              <PrimaryButton
                fullWidth
                onClick={submit}
                disabled={false}
                id="presence-save-call-notes-button"
              >
                Save changes
              </PrimaryButton>
            </Stack>
          </>
        ) : (
          <Stack space="s">
            <Inline alignY="center" space="xs">
              <Icon name="phone-tt" size="m" />
              <Text>{callContext.lead?.phone}</Text>
            </Inline>
            {callContext.lead?.email && (
              <Inline alignY="center" space="xs">
                <Icon name="delivered-tt" size="m" />
                <Text>
                  <UnderlinedLink href={`mailto:${callContext.lead?.email}`}>
                    {callContext.lead?.email}
                  </UnderlinedLink>
                </Text>
              </Inline>
            )}
            {callContext.lead?.birthdate && (
              <Inline alignY="center" space="xs">
                <Icon name="calendar-tt" size="m" />
                <Text>
                  {callContext.lead?.birthdate ? getBirthdate(callContext.lead?.birthdate) : ''}
                </Text>
              </Inline>
            )}
            {callContext.lead?.state && (
              <Inline alignY="top" space="xs">
                <Icon name="map-tt" size="m" />
                <Box maxWidth="120">
                  <Text>
                    {callContext.lead?.street_address ? callContext.lead?.street_address : ''}
                  </Text>
                  <Text>
                    {callContext.lead?.city ? callContext.lead.city + ', ' : ''}
                    {callContext.lead?.state ? callContext.lead.state + ', ' : ''}
                    {callContext.lead?.zip}
                  </Text>
                </Box>
              </Inline>
            )}
          </Stack>
        )}
      </Box>
    </Box>
  )
}
const UnderlinedLink = styled(TextLink)`
  text-decoration: underline;
  font-weight: var(--font-weight--regular);
`
export default LeadInfo
