import { TenantConfig } from '.'

const applyVoiceUserOverride = ({
  tenantConfig,
  user,
}: {
  tenantConfig: TenantConfig
  user: { organization?: { voice_enabled: boolean } }
}) => {
  if (user?.organization?.voice_enabled === true || user?.organization?.voice_enabled === false) {
    // OVERRIDE!
    return { ...tenantConfig, voice_enabled: user.organization.voice_enabled }
  } else {
    // No override
    return tenantConfig
  }
}

export default applyVoiceUserOverride
